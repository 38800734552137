<template>
  <DgLayoutPortalCommon
    :show-card="false"
    iconTitleName="CalendarMinus"
    :iconTitleColor="iconTitleColor"
    :title="title"
  >
    <template v-slot:actions>
      <div
        v-if="isFullscreen"
        :style="styleFullScreenClose"
        class="fullScreen__close"
        @click="setFullscreen(false)"
      >
        <DgIcon name="Minimize" class="fullScreen__close-icon" />
        <p class="fullScreen__close-label">{{ $t("PORTAL.HOME.LOGOUT") }}</p>
      </div>
    </template>
    <div v-if="withoutWidgets === 0" class="without-widgets">
      <div class="without-widgets__image"></div>
      <h4 class="without-widgets__text">
        {{ $t("PORTAL.HOME.WITHOUT_INFORMATION") }}
      </h4>
    </div>
    <grid-layout
      :layout.sync="layout"
      v-bind="{
        ...this.configGrid,
      }"
      :isMirrored="false"
      :responsive="false"
      verticalCompact
      :preventCollision="false"
      use-css-transforms
    >
      <template v-for="widget in layout">
        <grid-item
          :key="widget.i"
          :x="widget.x"
          :y="widget.y"
          :w="widget.w"
          :h="widget.h"
          :i="widget.i"
          :type="widget.type"
          :isResizable="false"
          :isDraggable="false"
        >
          <HomeRenderWidget
            :widget="widget.item"
            :loading="widget.loading || loading"
            :report-settings="reportSettings"
          />
        </grid-item>
      </template>
    </grid-layout>
  </DgLayoutPortalCommon>
</template>
<script>
import { GridLayout, GridItem } from "vue-grid-layout";
import { mapActions, mapState } from "vuex";
import HomeRenderWidget from "@/components/Home/RenderWidget";
import { GRID_CONFIGS } from "@/constants/gridConfigs";
import { DEFAULT_LANGUAGE } from "@/constants/language";
import { ACTIONS } from "@/constants/vuex";
import dayjs from "@/lib/dayjs";
import i18n from "@/lib/i18n";
import { fullscreen } from "@/mixins";
import MixinFixWidget from "@/mixins/fixWidget";
import MixinUpdateWidgets from "@/mixins/updateWidgets";
import { DgLayoutPortalCommon, DgIcon } from "@engenharia/dashgoo-ui";
import { isEmpty, MixinEmptyWidget } from "@engenharia/dashgoo-widgets";

export default {
  name: "Home",
  mixins: [fullscreen, MixinEmptyWidget, MixinFixWidget, MixinUpdateWidgets],
  computed: {
    ...mapState("dashboard", [
      "layout",
      "period",
      "loading",
      "dashboardLanguage",
      "settings",
    ]),
    ...mapState("user", ["logged"]),
    ...mapState({
      portalSettings: (state) => state.portal?.settings,
    }),
    withoutWidgets() {
      return this.layout?.length;
    },
    configGrid() {
      return GRID_CONFIGS;
    },
    title() {
      return `<b>${this.$t("PERIOD")}:</b> ${this.dateRangeStart} - ${
        this.dateRangeEnd
      }`;
    },
    language() {
      return this.dashboardLanguage || DEFAULT_LANGUAGE;
    },
    reportSettings() {
      const { settings = {} } = this.logged?.subscriber || {};
      return settings;
    },
    dateRangeStart() {
      const dateRangeStart = this.settings?.dateRangeStart;
      return dateRangeStart
        ? dayjs(dateRangeStart).format(`${this.dateFormat}`)
        : "";
    },
    dateRangeEnd() {
      const dateRangeEnd = this.settings?.dateRangeEnd;
      return dateRangeEnd
        ? dayjs(dateRangeEnd).format(`${this.dateFormat}`)
        : "";
    },
    iconTitleColor() {
      return this.portalSettings?.portalColor;
    },
    styleFullScreenClose() {
      return {
        backgroundColor: this.iconTitleColor,
      };
    },
  },
  data: () => ({
    DATE_PATTERN_EN_US: "MM/DD/YYYY",
    DATE_PATTERN_PT_BR: "DD/MM/YYYY",
    dateFormat: "DD/MM/YYYY",
    availableLanguages: ["pt-BR", "pt-PT", "pt", "en-US", "en", "es-ES", "es"],
  }),
  components: {
    DgIcon,
    DgLayoutPortalCommon,
    HomeRenderWidget,
    GridLayout,
    GridItem,
  },
  async mounted() {
    await this.getDashboard();
    this.verifyDateLanguage(this.language);
  },
  methods: {
    ...mapActions({
      getDashboard: `dashboard/${ACTIONS.DASHBOARD.GET_DASHBOARD}`,
    }),
    isWidgetEmpty(widget) {
      return isEmpty(widget.item, widget.item.type);
    },
    verifyDateLanguage(language) {
      this.dateFormat =
        language === "en-US"
          ? this.DATE_PATTERN_EN_US
          : this.DATE_PATTERN_PT_BR;
    },
  },
  watch: {
    language(newLanguage) {
      this.verifyDateLanguage(newLanguage);
      if (this.availableLanguages.includes(newLanguage)) {
        i18n.locale = newLanguage;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.without-widgets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: absolute;
  margin-top: 101px;
  width: 546px;
  height: 364px;

  &__image {
    background-image: url("/images/without-widgets.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  &__text {
    color: #474747;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    line-height: 30px;
    text-align: center;
  }
}
.fullScreen__close {
  border-radius: 16px 0px 0px 16px;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 20px;
  position: fixed;
  right: 0px;
  top: 27px;
  z-index: 100;
}

.fullScreen__close-icon {
  height: 24px;
  width: 24px;
}

.fullScreen__close-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0;
  margin: 0;
}
</style>

<style>
/* Specific style for emojis based on Twemoji */
img.emoji {
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.05em 0 0.1em !important;
  vertical-align: -0.1em !important;
  border-radius: initial !important;
  padding: 0 !important;
}
</style>
